import moment from "moment";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { FaCaretDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import Cards from "../../components/Cards/Cards";
import { downloadTransactions } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import axios from "../../plugins/axios";
import {
    defaultersStats,
    filterAllDefaultersTransactions,
  filterAllTransactions,
  revenueStats,
  transactionStats,
} from "../../plugins/urls";
import { copyValue } from "../../plugins/utils";
import TransactionCard from "../Cards/TransactionCard";
import NoResultFound from "../NoResultFound/NoResultFound";
import Pagination2 from "../Pagination/pagination2";
import FilterTransactions from "./FilterTransactions";
import Layout from "../Layout/Layout";

export const getRevenueStats = (setState) => {
  axios(defaultersStats)
    .then((res) => {
      if (res.data.respCode === 0) {
        const { gross_revenue, net_revenue } = res.data.respBody;
        setState((state) => ({
          ...state,
          gross_revenue,
          net_revenue,
        }));
      }
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const WayaposTransactionsContext = createContext();
const TerminalDefaulters = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const de37Ref = useRef(null);

  const [state, setState] = useState({
    transactions: [],
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 20,
    totalPages: 0,
    totaltransactions: 0,
    refunded: 0,
    failed: 0,
    abandoned: 0,
    successful: 0,
    gross_revenue: 0,
    net_revenue: 0,
  });

  const {
    pageNo,
    pageSize,
    totalPages,
    totalTransactions,
    transactions,
    failed,
    refunded,
    abandoned,
    successful,
    net_revenue,
    gross_revenue,
  } = state;
  const [filterParams, setFilterParams] = useState({});
  const [currentPage, setCurrentPage] = useState();
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const onFilterChange = (e) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      [e.target.name]: e.target.value,
    }));
  };
  const setStartDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: date,
    }));
  };
  const setEndDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      endDate: date,
    }));
  };
  const clearFilter = (e) => {
    e.preventDefault();
    setFilterParams({});
    getFilterTransaction(filterParams);
  };
  const clearRRN = (e) => {
    setSearchParam({});
    getFilterTransaction(filterParams);
  };

  const getFilterTransaction = (data) => {
    const qs = new URLSearchParams({
      ...data,
      pageNo: pageNo == 0 ? 1 : pageNo,
      pageSize,
      field: 'all',
      value: 'all'
    });
    setLoading(true);
    axios
      .get(`${filterAllDefaultersTransactions}?${qs}`)
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            transactions: res.data.respBody?.content,
            totalPages: res.data.respBody?.totalPages,
            totalTransactions: res.data.respBody?.totalElements,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message
              ? err.message
              : "Something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const getTransactionsStats = () => {
    axios(transactionStats)
      .then((res) => {
        if (res.data.respCode === 0) {
          const { failed, refunded, abandoned, successful } = res.data.respBody;
          setState((state) => ({
            ...state,
            failed,
            refunded,
            abandoned,
            successful,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response
              ? err.response.data
                ? err.response.data.message
                : "something went wrong"
              : err.response.data.error
              ? err.response.data.error
              : "something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const searchTransactionByReferenceID = () => {
    if (!searchParam) {
      toast.error(`${"Please input referenceID"}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    getFilterTransaction(searchParam);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchParam({ [name]: value });
  };

  const firstIndex = pageNo * pageSize;
  const lastIndex = firstIndex + pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected + 1,
    }));
    // getTerminals(selected);
    setCurrentPage(selected);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };

  useEffect(() => {
    // getTransactions();
    getTransactionsStats();
    // getRevenueStats(setState);
  }, []);
  useEffect(() => {
    getFilterTransaction(filterParams);
  }, [pageNo, pageSize]);
  const headers = [
    { label: "Reference ID", key: "de37" },
    { label: "Transaction Category", key: "transactionCategory" },
    { label: "Terminal Type", key: "terminalType" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Amount", key: "de4" },
    { label: "Created At", key: "createdAt" },
    { label: "Payment Status", key: "paymentStatus" },
  ];
  const csvReport = {
    data: transactions ?? [],
    headers: headers,
    filename: "WayaCashoutTransactions_Report.csv",
  };

  return (
    <Layout title="Daily Defaulters">
    
      <WayaposTransactionsContext.Provider
        value={{
          filterParams,
          setStartDate,
          setEndDate,
          clearFilter,
          onFilterChange,
          getFilterTransaction,
        }}
      >
        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-between filter-contents align-items-center">
            <div className="d-flex justify-content-start align-items-center ">
              <div className="d-flex justify-content-center align-items-center ">
                {/* <div className="d-flex justify-content-center align-items-center ">
                  <ExportData
                    url={downloadTransactions}
                    filename={`WAYAPOS transaction report-${new Date().toISOString()}`}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Container fluid>
          <div className="data-table mt-40">
            <table className="bg-inherit table table-borderless table-responsive w-100">
              <thead>
                <tr
                  className=" t-head-colored"
                  style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                >
                  <th>Reference Id</th>
                  <th>Merchant Id</th>
                  <th>Business Name</th>
                  <th>Account No</th>
                  <th>Description</th>
                  <th>Penalty Fee</th>
                  <th>Payment Status</th>
                  <th>Target Term</th>
                  <th>Due Date</th>
                  <th>Created At</th>
                  {/* <th>Settlement Status</th>
                  <th>Action</th> */}
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td style={{ display: "flex" }}>
                      <Spinner animation="border" role="status" />
                    </td>
                  </tr>
                ) : transactions && transactions.length > 0 ? (
                  transactions.map((transaction, i) => {
                    const {
                      accountNumber,
                      businessName,
                      description,
                      dueDate,
                      merchantId,
                      createdAt,
                      penaltyFee,
                      referenceNumber,
                      status,
                      targetTerm,
                      id,
                    } = transaction;
                    
                    const statusClass = () => {
                      if (status) {
                        switch (status.toLowerCase()) {
                          case "successful":
                            return "tabactive";
                          case "pending":
                            return "tabpending";
                          case "abandoned":
                            return "tabdamaged";
                          default:
                            return "tabdanger";
                        }
                      }
                    };

                    return (
                      <tr key={i}>
                        <td className=" td text-nowrap ">
                          {referenceNumber ? (
                            <span ref={de37Ref}>
                              {referenceNumber}
                              <i
                                className="ms-1 text-muted fs-16"
                                onClick={() => copyValue(referenceNumber)}
                              >
                                <BiCopy />
                              </i>
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {merchantId}
                          </span>
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {businessName}
                          </span>
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {accountNumber}
                          </span>
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {description}
                          </span>
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {penaltyFee}
                          </span>
                        </td>
                        <td className="td">
                          <span className={`${statusClass()}`}>
                            {status ? status : "N/A"}
                          </span>
                        </td>
                        <td className="td">
                          {targetTerm}
                        </td>
                        <td className="td">
                          {dueDate
                            ? moment(new Date(dueDate)).format("D/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td className="td">
                          {createdAt
                            ? moment(new Date(createdAt)).format("D/MM/YYYY")
                            : "N/A"}
                        </td>                        
                        {/* <td className="td">
                            {isSettled === true ? 'Settled' : isSettled === false ? "Not Settled" : '-'}
                        </td>
                        <td className="td text-nowrap">
                          <span
                            className="tabtransparent"
                            onClick={() => {
                              navigate(`/transaction/${id}`);
                            }}
                          >
                            View More
                          </span>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </table>
          </div>
          {transactions && transactions.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalTransactions}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </WayaposTransactionsContext.Provider>
    </Layout>
  );
};

export default TerminalDefaulters;
