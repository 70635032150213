import { ReactComponent as Dashboard } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as Logs } from "../../../assets/icons/logs.svg";
import { ReactComponent as Pos } from "../../../assets/icons/pos.svg";
import { ReactComponent as Settings } from "../../../assets/icons/settings.svg";
import { ReactComponent as Tech } from "../../../assets/icons/tech.svg";
import { ReactComponent as Transfer } from "../../../assets/icons/transfer.svg";
import { ReactComponent as User } from "../../../assets/icons/user.svg";

export const menuOptions = [
  {
    option: "DASHBOARD",
    label: "Dashboard",
    route: "/",
    title: "Dashboard",
    IconComp: Dashboard,
  },

  {
    option: ["MANAGE ADMIN USERS", "MANAGE CORPORATE USERS"],
    label: "Users",
    title: "Corporate",
    route: "#",
    IconComp: User,
    id: "userSubmenu",
    subOptions: [
      {
        option: "MANAGE ADMIN USERS",
        label: "Manage Admin Users",
        route: "/admin-users",
      },
      {
        option: "MANAGE CORPORATE USERS",
        label: "Manage Corporate Users",
        route: "/corporate-users",
      },
    ],
  },

  {
    option: "MANAGE TRANSACTIONS",
    label: "Transactions",
    route: "/transactions",
    title: "Transactions",
    IconComp: Transfer,
  },
  {
    option: "DEFAULTERS LOGS",
    label: "Defaulters Log",
    route: "/daily-defaulters",
    title: "Defaulters Log",
    IconComp: Transfer,
  },
  {
    option: "MANAGE KYC",
    label: "Manage KYC",
    route: "/manage-kyc",
    title: "Manage KYC",
    IconComp: Settings,
  },

  {
    option: "MANAGE TERMINAL",
    label: "Manage POS",
    route: "/manage-pos",
    title: "Manage POS",
    IconComp: Pos,
  },

  {
    option: ["MANAGE DISPUTE", "MANAGE AUDIT LOG"],
    label: "Logs",
    route: "#",
    title: "Logs",
    IconComp: Logs,
    id: "logSubmenu",
    subOptions: [
      {
        option: "MANAGE DISPUTE",
        label: "Dispute",
        route: "/disputes",
      },
      {
        option: "MANAGE AUDIT LOG",
        label: "Admin Logs",
        route: "/admin-logs",
      },
      {
        option: "MANAGE AUDIT LOG",
        label: "Request Logs",
        route: "/request-logs",
      },
    ],
  },

  {
    option: ["MANAGE PRICING", "MANAGE ROUTING"],
    label: "Tech",
    IconComp: Tech,
    id: "techSubmenu",
    subOptions: [
      { option: "MANAGE PRICING", label: "Pricings", route: "/pricing" },
      { option: "MANAGE ROUTING", label: "Routing", route: "/routing" },
    ],
  },
  {
    option: "BUSINESS REPORTING",
    label: "Business Report",
    route: "/business-reports",
    title: "Business Reports",
    IconComp: Settings,
  },
  {
    option: "REMOTE UPDATE",
    label: "Remote Update",
    route: "/remote-update",
    title: "Remote Update",
    IconComp: Settings,
  },
];
